import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/iOS/InstarVision/Wizard/iPad/Install/IN-6001-8001/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer iPhone oder iPad App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_iOS.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_iOS.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-iOS-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "ipad-app-installationsassistent",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ipad-app-installationsassistent",
        "aria-label": "ipad app installationsassistent permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iPad App Installationsassistent`}</h2>
    <h3 {...{
      "id": "hinzufügen-einer-neuen-ip-kamera-in-6001-hd--in-8001-fhd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hinzuf%C3%BCgen-einer-neuen-ip-kamera-in-6001-hd--in-8001-fhd",
        "aria-label": "hinzufügen einer neuen ip kamera in 6001 hd  in 8001 fhd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen einer neuen IP Kamera (`}<em parentName="h3">{`IN-6001 HD & IN-8001 FHD`}</em>{`)`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/49853/iPad_Wizard_Installation_8001_6001_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABO0lEQVQoz5WSbW/bMAyE/f//Zlagmy0rJCW+ibKGZIubOknRHp5PFA6idDf9Xs7zkn6dTiklVS1Vc+3mvbUL7hGxIRbEErH9G0ZsherpPU9nJGZWVWZuEaKe0Uq1eoPZAQpAZfZ9KGyZ2rSuKaU0booWhALwCURF1M9DRuAJAEopY4ztqtYCUb7JlHMmojFG7/2pmUh3nphTSttN7bp2IaHrMQAvC8xznufzsgAA3zNFhLvv5t77gvqedQFVNfdWq5TCpXCt7B47ojGNB2G1TFqqtObjtVqMaTuq52IziIows4i8MvujOSIuSxIjXiDiepf5PaXa0fz424fM7zm+OaL/IOcvbr7U6DvmvWE9ot5CPhSD6KM2/81mJiIppVq5uf0Be1s9kbu3dT3nDCJq5gBEVM1cRBGLiJn5Xz4nZE4YBR+WAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/e4706/iPad_Wizard_Installation_8001_6001_01.avif 230w", "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/d1af7/iPad_Wizard_Installation_8001_6001_01.avif 460w", "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/7f308/iPad_Wizard_Installation_8001_6001_01.avif 920w", "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/e1c99/iPad_Wizard_Installation_8001_6001_01.avif 1380w", "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/76ea5/iPad_Wizard_Installation_8001_6001_01.avif 1840w", "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/b105c/iPad_Wizard_Installation_8001_6001_01.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/a0b58/iPad_Wizard_Installation_8001_6001_01.webp 230w", "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/bc10c/iPad_Wizard_Installation_8001_6001_01.webp 460w", "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/966d8/iPad_Wizard_Installation_8001_6001_01.webp 920w", "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/445df/iPad_Wizard_Installation_8001_6001_01.webp 1380w", "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/78de1/iPad_Wizard_Installation_8001_6001_01.webp 1840w", "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/03b06/iPad_Wizard_Installation_8001_6001_01.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/81c8e/iPad_Wizard_Installation_8001_6001_01.png 230w", "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/08a84/iPad_Wizard_Installation_8001_6001_01.png 460w", "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/c0255/iPad_Wizard_Installation_8001_6001_01.png 920w", "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/b1001/iPad_Wizard_Installation_8001_6001_01.png 1380w", "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/161ec/iPad_Wizard_Installation_8001_6001_01.png 1840w", "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/49853/iPad_Wizard_Installation_8001_6001_01.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/22eea9c2a1ea613eb6674f10ddd7cb40/c0255/iPad_Wizard_Installation_8001_6001_01.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Beginnen Sie mit dem Menü `}<strong parentName="li">{`Kameras`}</strong>{` und wählen Sie den `}<strong parentName="li">{`Installationsassistenten`}</strong>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/37943bf5a94b557e1cdaf0487ef88235/49853/iPad_Wizard_Installation_8001_6001_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVQoz32QW2vbQBCF9cfbl2AKJeSHtNA+B2yaPrUPvefBlKSWJVmxbqu97+zMrqwiOzYxND0chuHsfszsJnUn2o5tioIx5r03znM7gI9Ek0MYrHV13bRtZy2EMBCFEAatTV71iVDaOee9d87FGMGTMAQQEaP3ATEqZeu6a5pOKYM4eB88RsJB2pj0PWOMjUfFOHQtz/NtUWyz7KEoqtWqSNNNmk61KKqiqLKszPNt2/BESmmMGcdxGHa73S6EWJZ1mm6yrEzTzXpd5vnDcnm/XN6t149Jmhbr9aYsm4RzrrXew8MBNgatpZOdC1p7Y9C58DQ3BieYMbY7KoSoFJwspRPCSOn63rSteJorBUmMkYiegT3v1SrvNtv+9tfy0+cvXSu09qcLyXiuGIezycKWFa/q/vdd9vX2T9fyM3h3rtNkIaGXwCW0neq5ZdxWjRTKczkd/ReWENGN8WAYg5sc4ZBE756FpQJr4M03fHEdZotwMQ+vFpMv5jRbhJfX4e13dAbkP988wRre/cDZnC5v6PUHuvpIV/vm8oZmc3r/8wg/t3bw+1XpWA/N3gHO1x7H8dAMMVoNWoEQIKQX0nMB/LyX6vHDE0QEAMaYtS4QNhLLnpgmotD3gnMJ4BFJSq21RSQAr5QBQET6CySHTvpE60UtAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/37943bf5a94b557e1cdaf0487ef88235/e4706/iPad_Wizard_Installation_8001_6001_02.avif 230w", "/en/static/37943bf5a94b557e1cdaf0487ef88235/d1af7/iPad_Wizard_Installation_8001_6001_02.avif 460w", "/en/static/37943bf5a94b557e1cdaf0487ef88235/7f308/iPad_Wizard_Installation_8001_6001_02.avif 920w", "/en/static/37943bf5a94b557e1cdaf0487ef88235/e1c99/iPad_Wizard_Installation_8001_6001_02.avif 1380w", "/en/static/37943bf5a94b557e1cdaf0487ef88235/76ea5/iPad_Wizard_Installation_8001_6001_02.avif 1840w", "/en/static/37943bf5a94b557e1cdaf0487ef88235/b105c/iPad_Wizard_Installation_8001_6001_02.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/37943bf5a94b557e1cdaf0487ef88235/a0b58/iPad_Wizard_Installation_8001_6001_02.webp 230w", "/en/static/37943bf5a94b557e1cdaf0487ef88235/bc10c/iPad_Wizard_Installation_8001_6001_02.webp 460w", "/en/static/37943bf5a94b557e1cdaf0487ef88235/966d8/iPad_Wizard_Installation_8001_6001_02.webp 920w", "/en/static/37943bf5a94b557e1cdaf0487ef88235/445df/iPad_Wizard_Installation_8001_6001_02.webp 1380w", "/en/static/37943bf5a94b557e1cdaf0487ef88235/78de1/iPad_Wizard_Installation_8001_6001_02.webp 1840w", "/en/static/37943bf5a94b557e1cdaf0487ef88235/03b06/iPad_Wizard_Installation_8001_6001_02.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/37943bf5a94b557e1cdaf0487ef88235/81c8e/iPad_Wizard_Installation_8001_6001_02.png 230w", "/en/static/37943bf5a94b557e1cdaf0487ef88235/08a84/iPad_Wizard_Installation_8001_6001_02.png 460w", "/en/static/37943bf5a94b557e1cdaf0487ef88235/c0255/iPad_Wizard_Installation_8001_6001_02.png 920w", "/en/static/37943bf5a94b557e1cdaf0487ef88235/b1001/iPad_Wizard_Installation_8001_6001_02.png 1380w", "/en/static/37943bf5a94b557e1cdaf0487ef88235/161ec/iPad_Wizard_Installation_8001_6001_02.png 1840w", "/en/static/37943bf5a94b557e1cdaf0487ef88235/49853/iPad_Wizard_Installation_8001_6001_02.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/37943bf5a94b557e1cdaf0487ef88235/c0255/iPad_Wizard_Installation_8001_6001_02.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` Sie finden den `}<strong parentName="li">{`QR-Code`}</strong>{` in der `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/Internet_Access/"
        }}>{`WebUI`}</a>{` (A), auf dem Etikett Ihrer Kamera (B) oder auf der Verpackung abgedruckt.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/49853/iPad_Wizard_Installation_8001_6001_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAyklEQVQoz62S3WqEQAyFJ5k1Y36G0VQUvRF8/4csjHRxi4tu6XeZ5JCTcEJ4EBCHP4IR4iP8L3Dg1jQztz+4+zAMXxURedZjjGf2EVUVEWMFEQEAK88KEbVtey4+bxyIMaaUrsWI2FSOPq/FXBnHcV3XbdumaZLKLXHO2d1LKSKSczazvu/d/QPbH9/MzJfi8wUAYGbMbGYiwq/sLRFpmuZtThBxWRYi+mVqnueU0nXa9hgxcyml6zoz20+9ldMQAhGpqlVU9d0vvgHlHwsUrtTI2gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/e4706/iPad_Wizard_Installation_8001_6001_03.avif 230w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/d1af7/iPad_Wizard_Installation_8001_6001_03.avif 460w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/7f308/iPad_Wizard_Installation_8001_6001_03.avif 920w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/e1c99/iPad_Wizard_Installation_8001_6001_03.avif 1380w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/76ea5/iPad_Wizard_Installation_8001_6001_03.avif 1840w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/b105c/iPad_Wizard_Installation_8001_6001_03.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/a0b58/iPad_Wizard_Installation_8001_6001_03.webp 230w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/bc10c/iPad_Wizard_Installation_8001_6001_03.webp 460w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/966d8/iPad_Wizard_Installation_8001_6001_03.webp 920w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/445df/iPad_Wizard_Installation_8001_6001_03.webp 1380w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/78de1/iPad_Wizard_Installation_8001_6001_03.webp 1840w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/03b06/iPad_Wizard_Installation_8001_6001_03.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/81c8e/iPad_Wizard_Installation_8001_6001_03.png 230w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/08a84/iPad_Wizard_Installation_8001_6001_03.png 460w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/c0255/iPad_Wizard_Installation_8001_6001_03.png 920w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/b1001/iPad_Wizard_Installation_8001_6001_03.png 1380w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/161ec/iPad_Wizard_Installation_8001_6001_03.png 1840w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/49853/iPad_Wizard_Installation_8001_6001_03.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/c0255/iPad_Wizard_Installation_8001_6001_03.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Verwenden Sie die App, um den QR-Code von Ihrer Kamera oder Ihrem Kamerapaket zu scannen. Wenn Ihre Kamera keinen QR-Code hat, wählen Sie bitte im nächsten Schritt das Kameramodell manuell aus.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/94a8667ee34e4fef9a64aad1212d3221/49853/iPad_Wizard_Installation_8001_6001_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABBklEQVQoz52TYW8DIQiG/f+/csn2pVdB1KuIgrfkTG/Nmuy6Pt94w0sA0V08XT18fX4CQK013xiyXJP4VAszcxERAEBEEeGdWisRLcviKOVSSq21lNK7VukLRMqFm7XWe++qOj2q2u+oqpk5RACA7c4YA/x1zWn7k7HjYozruh6xmeGOmY0zXAgh57xt28w2M+99COEod2IGgCM2sxBCSmm8gFPV1tqj5L1HxJfMz8tAxJROFjZxv4r9r+1nMxHFGN8xz5mJ6J2ZxxiISETvzDxseA/MfLz8eduPJ0EBK5epHPrjVf6c5/wrAHC7ld5kifKxrBdcpbWww8wiEmPMOc/klNIUvwHvCXHbfiAJ6AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/94a8667ee34e4fef9a64aad1212d3221/e4706/iPad_Wizard_Installation_8001_6001_04.avif 230w", "/en/static/94a8667ee34e4fef9a64aad1212d3221/d1af7/iPad_Wizard_Installation_8001_6001_04.avif 460w", "/en/static/94a8667ee34e4fef9a64aad1212d3221/7f308/iPad_Wizard_Installation_8001_6001_04.avif 920w", "/en/static/94a8667ee34e4fef9a64aad1212d3221/e1c99/iPad_Wizard_Installation_8001_6001_04.avif 1380w", "/en/static/94a8667ee34e4fef9a64aad1212d3221/76ea5/iPad_Wizard_Installation_8001_6001_04.avif 1840w", "/en/static/94a8667ee34e4fef9a64aad1212d3221/b105c/iPad_Wizard_Installation_8001_6001_04.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/94a8667ee34e4fef9a64aad1212d3221/a0b58/iPad_Wizard_Installation_8001_6001_04.webp 230w", "/en/static/94a8667ee34e4fef9a64aad1212d3221/bc10c/iPad_Wizard_Installation_8001_6001_04.webp 460w", "/en/static/94a8667ee34e4fef9a64aad1212d3221/966d8/iPad_Wizard_Installation_8001_6001_04.webp 920w", "/en/static/94a8667ee34e4fef9a64aad1212d3221/445df/iPad_Wizard_Installation_8001_6001_04.webp 1380w", "/en/static/94a8667ee34e4fef9a64aad1212d3221/78de1/iPad_Wizard_Installation_8001_6001_04.webp 1840w", "/en/static/94a8667ee34e4fef9a64aad1212d3221/03b06/iPad_Wizard_Installation_8001_6001_04.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/94a8667ee34e4fef9a64aad1212d3221/81c8e/iPad_Wizard_Installation_8001_6001_04.png 230w", "/en/static/94a8667ee34e4fef9a64aad1212d3221/08a84/iPad_Wizard_Installation_8001_6001_04.png 460w", "/en/static/94a8667ee34e4fef9a64aad1212d3221/c0255/iPad_Wizard_Installation_8001_6001_04.png 920w", "/en/static/94a8667ee34e4fef9a64aad1212d3221/b1001/iPad_Wizard_Installation_8001_6001_04.png 1380w", "/en/static/94a8667ee34e4fef9a64aad1212d3221/161ec/iPad_Wizard_Installation_8001_6001_04.png 1840w", "/en/static/94a8667ee34e4fef9a64aad1212d3221/49853/iPad_Wizard_Installation_8001_6001_04.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/94a8667ee34e4fef9a64aad1212d3221/c0255/iPad_Wizard_Installation_8001_6001_04.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Wählen Sie Ihr Kameramodell, z.B. `}<strong parentName="li">{`IN-8001FullHD`}</strong></li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a3efaed608dd627ab2392b4ef8172484/49853/iPad_Wizard_Installation_8001_6001_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABcUlEQVQoz42SQW7cMAxFfbR2WSBAe6jkElkUQa7R3qQBgnQySWwNJUuUSEqi7cBuJqg7nrYff0F94YGUwKY99OYAj7sdAIhIJLFpIBm0DrUOpajqiBgRk+pYl1B1jJh2L67pAxKRiBBRVWUpNmSMOaU3ExVjrDGWqLyHTNlhbawFAJiO0qoY2PuVrUXncB1S8NR472OM0zSNi2rVEPgPx5gR5TRvnHOIOE3TMAzn4K611oZtGADGo07hvk93P+6f9t0GrKqllL/D+8cX07kNeFpLddgYu3MAW2OPa5129p6Mcca4+Yf/E47IKb45IsWQfjv+C4aeW8vd4tZKa+VYz1dn3+w9c+TLb+XDtV7c6Kev+uVWP9/OxcWNfrzWq+9F0rwqZzs/H/ih5Z/d7N3iX/VDy8+wHvt9wwbVhIyBE7IkkSQcmSJzWrwUFI9wzpmZASAlqiV3Pu9tASylVGt75zyz5Fy8R8SUc2GWECJzzrm8AjGVVdyL7TMDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a3efaed608dd627ab2392b4ef8172484/e4706/iPad_Wizard_Installation_8001_6001_05.avif 230w", "/en/static/a3efaed608dd627ab2392b4ef8172484/d1af7/iPad_Wizard_Installation_8001_6001_05.avif 460w", "/en/static/a3efaed608dd627ab2392b4ef8172484/7f308/iPad_Wizard_Installation_8001_6001_05.avif 920w", "/en/static/a3efaed608dd627ab2392b4ef8172484/e1c99/iPad_Wizard_Installation_8001_6001_05.avif 1380w", "/en/static/a3efaed608dd627ab2392b4ef8172484/76ea5/iPad_Wizard_Installation_8001_6001_05.avif 1840w", "/en/static/a3efaed608dd627ab2392b4ef8172484/b105c/iPad_Wizard_Installation_8001_6001_05.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a3efaed608dd627ab2392b4ef8172484/a0b58/iPad_Wizard_Installation_8001_6001_05.webp 230w", "/en/static/a3efaed608dd627ab2392b4ef8172484/bc10c/iPad_Wizard_Installation_8001_6001_05.webp 460w", "/en/static/a3efaed608dd627ab2392b4ef8172484/966d8/iPad_Wizard_Installation_8001_6001_05.webp 920w", "/en/static/a3efaed608dd627ab2392b4ef8172484/445df/iPad_Wizard_Installation_8001_6001_05.webp 1380w", "/en/static/a3efaed608dd627ab2392b4ef8172484/78de1/iPad_Wizard_Installation_8001_6001_05.webp 1840w", "/en/static/a3efaed608dd627ab2392b4ef8172484/03b06/iPad_Wizard_Installation_8001_6001_05.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a3efaed608dd627ab2392b4ef8172484/81c8e/iPad_Wizard_Installation_8001_6001_05.png 230w", "/en/static/a3efaed608dd627ab2392b4ef8172484/08a84/iPad_Wizard_Installation_8001_6001_05.png 460w", "/en/static/a3efaed608dd627ab2392b4ef8172484/c0255/iPad_Wizard_Installation_8001_6001_05.png 920w", "/en/static/a3efaed608dd627ab2392b4ef8172484/b1001/iPad_Wizard_Installation_8001_6001_05.png 1380w", "/en/static/a3efaed608dd627ab2392b4ef8172484/161ec/iPad_Wizard_Installation_8001_6001_05.png 1840w", "/en/static/a3efaed608dd627ab2392b4ef8172484/49853/iPad_Wizard_Installation_8001_6001_05.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a3efaed608dd627ab2392b4ef8172484/c0255/iPad_Wizard_Installation_8001_6001_05.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/15482375215ce28b16569994959a6cc1/49853/iPad_Wizard_Installation_8001_6001_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABV0lEQVQoz5VS227rMAzL//9mN6BN5ji2bN1tD2mXoN1whh2CD4QgWjLE6faxLR/h7XIJITBzQY61kTS3ZtZU3b3nXHIu7t3uRfdeoF6ucdoyICIzI6K5E2vMAkVq/SKiplRSqoh6Fgklgk3rGkII44CbQ6aUXpgz58yvRcwJp5RSKWWM0e8w85zpj5xijAAwxmitPZsB+F+8L3KYQwj9gJmXvYOWZZvnMM/rssR5Xg/G2y0sSwSgAjS5u6qe5tZaRhM1EeE7noWZm1lrnrlfoU/jFb33ikxED8MpEFFEzjaxATym/gOxygosvNtUH4O/nnD3hxlkXOGHubVWkfdLkuC+wkPs+p4GFTEVK2hbse/m30/1dHmCTN//7N7+486/TN5j9BfzmbDmXmFf6czJ2QpwiiMkIkJEIYRa0VTmJO+rBlBVW9ctxkTEIpoSAFQRJeKcC5GI6CfJVmOW5fuHXwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/15482375215ce28b16569994959a6cc1/e4706/iPad_Wizard_Installation_8001_6001_06.avif 230w", "/en/static/15482375215ce28b16569994959a6cc1/d1af7/iPad_Wizard_Installation_8001_6001_06.avif 460w", "/en/static/15482375215ce28b16569994959a6cc1/7f308/iPad_Wizard_Installation_8001_6001_06.avif 920w", "/en/static/15482375215ce28b16569994959a6cc1/e1c99/iPad_Wizard_Installation_8001_6001_06.avif 1380w", "/en/static/15482375215ce28b16569994959a6cc1/76ea5/iPad_Wizard_Installation_8001_6001_06.avif 1840w", "/en/static/15482375215ce28b16569994959a6cc1/b105c/iPad_Wizard_Installation_8001_6001_06.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/15482375215ce28b16569994959a6cc1/a0b58/iPad_Wizard_Installation_8001_6001_06.webp 230w", "/en/static/15482375215ce28b16569994959a6cc1/bc10c/iPad_Wizard_Installation_8001_6001_06.webp 460w", "/en/static/15482375215ce28b16569994959a6cc1/966d8/iPad_Wizard_Installation_8001_6001_06.webp 920w", "/en/static/15482375215ce28b16569994959a6cc1/445df/iPad_Wizard_Installation_8001_6001_06.webp 1380w", "/en/static/15482375215ce28b16569994959a6cc1/78de1/iPad_Wizard_Installation_8001_6001_06.webp 1840w", "/en/static/15482375215ce28b16569994959a6cc1/03b06/iPad_Wizard_Installation_8001_6001_06.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/15482375215ce28b16569994959a6cc1/81c8e/iPad_Wizard_Installation_8001_6001_06.png 230w", "/en/static/15482375215ce28b16569994959a6cc1/08a84/iPad_Wizard_Installation_8001_6001_06.png 460w", "/en/static/15482375215ce28b16569994959a6cc1/c0255/iPad_Wizard_Installation_8001_6001_06.png 920w", "/en/static/15482375215ce28b16569994959a6cc1/b1001/iPad_Wizard_Installation_8001_6001_06.png 1380w", "/en/static/15482375215ce28b16569994959a6cc1/161ec/iPad_Wizard_Installation_8001_6001_06.png 1840w", "/en/static/15482375215ce28b16569994959a6cc1/49853/iPad_Wizard_Installation_8001_6001_06.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/15482375215ce28b16569994959a6cc1/c0255/iPad_Wizard_Installation_8001_6001_06.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Wählen Sie aus, ob Ihre Kamera über `}<strong parentName="li">{`WiFi`}</strong>{` angeschlossen werden soll oder ob Sie ein Ethernet-Kabel verwenden werden.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/49853/iPad_Wizard_Installation_8001_6001_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABv0lEQVQoz4WSWY4TMRCGczd4HAkJzoTEIeZhBBwDcQ4WkZmku+klLm9lu8pLp1G6J8kkmohf/0Mt+uyyyqte6J2Auq4AgJldYOVH4pJTSbP3+wnRIfpxnJZKziOirzq10hZDCMwcQsilECdpow+JORPnEKK1XkojpbHWhxCZM3OOnBXmlZQAANNRORe0NPSqafqmGeq6W6+rxY+PVV33bSuqqus6QEsrY4xzbpqm/ayci7UkJQLYxbudfnpqNptGCL3UhTBSorW0Ukoh4jRN4zieYEQ+20Xvs/ex78XSci4i8jMMAPujFvhkRG6q7vfPtVK428mXrQNcSkkpvQobExB5u/n768cfCVZJtPOFZ3i6VCnj1fF9B9W2BTBSWrSX8P5SV2NbS1qjBAXCGO2vx74Fa0NCE2jqhW0HIxRJzTBXlLkNG0vk6OO39Oa+vPtc7h7GD1/H91/K3cMhfXtfPn1P7MmY229uBW163g68GWg7UDUc4jnlVvxv7OCIfSBP7A+DHDzH7Mm7S/j0w8ZSPBIel4wvdoN4Xv4zHGMkIgDwPuQUBxMbmQBTSllKrZQh4hiTMYjoY0xEbK0jijGmfxYIUr+ScZXpAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/e4706/iPad_Wizard_Installation_8001_6001_07.avif 230w", "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/d1af7/iPad_Wizard_Installation_8001_6001_07.avif 460w", "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/7f308/iPad_Wizard_Installation_8001_6001_07.avif 920w", "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/e1c99/iPad_Wizard_Installation_8001_6001_07.avif 1380w", "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/76ea5/iPad_Wizard_Installation_8001_6001_07.avif 1840w", "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/b105c/iPad_Wizard_Installation_8001_6001_07.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/a0b58/iPad_Wizard_Installation_8001_6001_07.webp 230w", "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/bc10c/iPad_Wizard_Installation_8001_6001_07.webp 460w", "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/966d8/iPad_Wizard_Installation_8001_6001_07.webp 920w", "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/445df/iPad_Wizard_Installation_8001_6001_07.webp 1380w", "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/78de1/iPad_Wizard_Installation_8001_6001_07.webp 1840w", "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/03b06/iPad_Wizard_Installation_8001_6001_07.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/81c8e/iPad_Wizard_Installation_8001_6001_07.png 230w", "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/08a84/iPad_Wizard_Installation_8001_6001_07.png 460w", "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/c0255/iPad_Wizard_Installation_8001_6001_07.png 920w", "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/b1001/iPad_Wizard_Installation_8001_6001_07.png 1380w", "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/161ec/iPad_Wizard_Installation_8001_6001_07.png 1840w", "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/49853/iPad_Wizard_Installation_8001_6001_07.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7e35e8d3a55e71e801d7eb1bf8c1599c/c0255/iPad_Wizard_Installation_8001_6001_07.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[08]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/49853/iPad_Wizard_Installation_8001_6001_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1klEQVQoz4VSbYrcMAzN3dqfhcLeqdBD7I/SXqP0FKVQ2CWJk2xmJ/6SZVuyY09KZvazZenjYZ4tPUtGbu6lXaQax0Epxcw+svGVuOT8wFo3RI8Yat1yLimVda2IXhx0Yx3GGJk5xlhKIc4WM/GexFxiTM4FKa3WgHtiOp+va67gS6O1UkptjyilLkctxCzEPAyHtp1ub0XbDm073tz0XTcJMY/jfddNUpoGALz327adziilKuXadmzbsev2VYi780Vz3099f9d1U9dNbTtqjY0xBhG3bau1nk6ndS2IHEK+EJEBonMRkZbFWhtiXJ9Cu1kpdXrEuhbnyDkCiIi8HM0g5sNBjsPh18/fy2IQ+ZLgHDWllJzzv+YLz5WDUk5r3FvYu3iONttrlFJfhsEGY7yUICVo5V6GdvPpNf6qrLWT0lrjAaKSAH9VfstsgaQlaeKiw6KDOmtlSVky8LYZHJGnT9/zu+vy8Wv58KVefStXu9i376/L5x+ZAwG8/eZZUn/P4kjiSMPOi+b+yLP8X9vRE4dIgTjsjew8aw4U/Gvz0w+rpQQkfJzTy6kiPs/vwZxSIiKlVAhxzekIadJZYc551doaA0ScUgZAxJBSJmLnPFFKKf8Bw/1Q6q9GjqUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/e4706/iPad_Wizard_Installation_8001_6001_08.avif 230w", "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/d1af7/iPad_Wizard_Installation_8001_6001_08.avif 460w", "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/7f308/iPad_Wizard_Installation_8001_6001_08.avif 920w", "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/e1c99/iPad_Wizard_Installation_8001_6001_08.avif 1380w", "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/76ea5/iPad_Wizard_Installation_8001_6001_08.avif 1840w", "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/b105c/iPad_Wizard_Installation_8001_6001_08.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/a0b58/iPad_Wizard_Installation_8001_6001_08.webp 230w", "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/bc10c/iPad_Wizard_Installation_8001_6001_08.webp 460w", "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/966d8/iPad_Wizard_Installation_8001_6001_08.webp 920w", "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/445df/iPad_Wizard_Installation_8001_6001_08.webp 1380w", "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/78de1/iPad_Wizard_Installation_8001_6001_08.webp 1840w", "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/03b06/iPad_Wizard_Installation_8001_6001_08.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/81c8e/iPad_Wizard_Installation_8001_6001_08.png 230w", "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/08a84/iPad_Wizard_Installation_8001_6001_08.png 460w", "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/c0255/iPad_Wizard_Installation_8001_6001_08.png 920w", "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/b1001/iPad_Wizard_Installation_8001_6001_08.png 1380w", "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/161ec/iPad_Wizard_Installation_8001_6001_08.png 1840w", "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/49853/iPad_Wizard_Installation_8001_6001_08.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ca3bf7c0e72e285aec8e66ad5cf0c84b/c0255/iPad_Wizard_Installation_8001_6001_08.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[09]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cd82e84c551aa8fc84c114e56f84e538/49853/iPad_Wizard_Installation_8001_6001_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAByUlEQVQoz4VSS24bMQydw2UbZJsLFeghskkXOUXRgwQG5m/PT9JIFEV9OJ5iPLVRG0j78BbkI58+BLN+msdJNE0thPDeW+elXZznFDlEjpHP5xUAAXBZ1pQ2JaUFwFadzGYDzjnvvXMuMZOPGqKjFAKHwIjBGJwmJaUxBhF9COx9CmHRyJmUQgixXsGJ+17WdVcUbVWd8rw5HIo8r/O8PhyKomjrumuavq67vheZ1tpau67r+YKUWCk7DCrPmzxv9iOq6lQUbVkey7K98DiOs1I2U0oBwLquy7LsZgAP4BEjYnQuzTOOo7KWnIu7aG3cezazEOJ8RUpsDO3U2lkbju3w+Zm3nZGzv5bcHmTMHGP8ygzgh0Ed26Fo9Shv5j/M1nswLw8d2wh6KYUGoEfz+R5/37xTStN3kxT6Qf+XedY0zSRmmpQbpBsEiksqZlL6a7M2RJa+/YxPb/zyg5/f+fVjef1Ynt+39OmNv/+KHknrr/98mqjsfTVsbMaNe1wO/jT979nOkkdHSBvtlhKSvxDtvfm2YQszAsGltm/CbTy3ad/ELIRAREIIRJdiGHRoZRQQY0xSzkppIh9C1BoAMIRI5I2xRCGE+BsbX1GJwgi+RwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd82e84c551aa8fc84c114e56f84e538/e4706/iPad_Wizard_Installation_8001_6001_09.avif 230w", "/en/static/cd82e84c551aa8fc84c114e56f84e538/d1af7/iPad_Wizard_Installation_8001_6001_09.avif 460w", "/en/static/cd82e84c551aa8fc84c114e56f84e538/7f308/iPad_Wizard_Installation_8001_6001_09.avif 920w", "/en/static/cd82e84c551aa8fc84c114e56f84e538/e1c99/iPad_Wizard_Installation_8001_6001_09.avif 1380w", "/en/static/cd82e84c551aa8fc84c114e56f84e538/76ea5/iPad_Wizard_Installation_8001_6001_09.avif 1840w", "/en/static/cd82e84c551aa8fc84c114e56f84e538/b105c/iPad_Wizard_Installation_8001_6001_09.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cd82e84c551aa8fc84c114e56f84e538/a0b58/iPad_Wizard_Installation_8001_6001_09.webp 230w", "/en/static/cd82e84c551aa8fc84c114e56f84e538/bc10c/iPad_Wizard_Installation_8001_6001_09.webp 460w", "/en/static/cd82e84c551aa8fc84c114e56f84e538/966d8/iPad_Wizard_Installation_8001_6001_09.webp 920w", "/en/static/cd82e84c551aa8fc84c114e56f84e538/445df/iPad_Wizard_Installation_8001_6001_09.webp 1380w", "/en/static/cd82e84c551aa8fc84c114e56f84e538/78de1/iPad_Wizard_Installation_8001_6001_09.webp 1840w", "/en/static/cd82e84c551aa8fc84c114e56f84e538/03b06/iPad_Wizard_Installation_8001_6001_09.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd82e84c551aa8fc84c114e56f84e538/81c8e/iPad_Wizard_Installation_8001_6001_09.png 230w", "/en/static/cd82e84c551aa8fc84c114e56f84e538/08a84/iPad_Wizard_Installation_8001_6001_09.png 460w", "/en/static/cd82e84c551aa8fc84c114e56f84e538/c0255/iPad_Wizard_Installation_8001_6001_09.png 920w", "/en/static/cd82e84c551aa8fc84c114e56f84e538/b1001/iPad_Wizard_Installation_8001_6001_09.png 1380w", "/en/static/cd82e84c551aa8fc84c114e56f84e538/161ec/iPad_Wizard_Installation_8001_6001_09.png 1840w", "/en/static/cd82e84c551aa8fc84c114e56f84e538/49853/iPad_Wizard_Installation_8001_6001_09.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cd82e84c551aa8fc84c114e56f84e538/c0255/iPad_Wizard_Installation_8001_6001_09.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[10]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8917d7d6092cda83b25603f97d78f84a/49853/iPad_Wizard_Installation_8001_6001_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABI0lEQVQoz62Ty27EIAxF8/8/2UUzCybDAOFlG2yqhCYiXUxbtWdhyRdf2ZbwpB52eeh5ftdaA4CPebHZx4zwSSnFGGOtJaKuIKKz9u12n+zqU0oAkFIqpQJSJqksZYeIQgjdHGMsB1xrQp6eT621bgfCvDrrnBORruAOESFia0OhyOScCyFs2Q4zL8uilCKiusMD9YBrzVgnY4z3vrXGzN2slJrnGQB6eo7Q044wA/Fm1lrLATP3rU4FIMcYYgwpRhlglqnWSkSjeVisVW4RyIXsE4SEVcbHNrUr55AdKG3F5qD5PeZyNcuVL51f841ZjihD+k+dX+/8p7F/ZD5/2O86I2LOWWsdYyqEd4c3g08PRNsxGWNyztsZOee978XrunbxAyXDc6Fphtq/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8917d7d6092cda83b25603f97d78f84a/e4706/iPad_Wizard_Installation_8001_6001_10.avif 230w", "/en/static/8917d7d6092cda83b25603f97d78f84a/d1af7/iPad_Wizard_Installation_8001_6001_10.avif 460w", "/en/static/8917d7d6092cda83b25603f97d78f84a/7f308/iPad_Wizard_Installation_8001_6001_10.avif 920w", "/en/static/8917d7d6092cda83b25603f97d78f84a/e1c99/iPad_Wizard_Installation_8001_6001_10.avif 1380w", "/en/static/8917d7d6092cda83b25603f97d78f84a/76ea5/iPad_Wizard_Installation_8001_6001_10.avif 1840w", "/en/static/8917d7d6092cda83b25603f97d78f84a/b105c/iPad_Wizard_Installation_8001_6001_10.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8917d7d6092cda83b25603f97d78f84a/a0b58/iPad_Wizard_Installation_8001_6001_10.webp 230w", "/en/static/8917d7d6092cda83b25603f97d78f84a/bc10c/iPad_Wizard_Installation_8001_6001_10.webp 460w", "/en/static/8917d7d6092cda83b25603f97d78f84a/966d8/iPad_Wizard_Installation_8001_6001_10.webp 920w", "/en/static/8917d7d6092cda83b25603f97d78f84a/445df/iPad_Wizard_Installation_8001_6001_10.webp 1380w", "/en/static/8917d7d6092cda83b25603f97d78f84a/78de1/iPad_Wizard_Installation_8001_6001_10.webp 1840w", "/en/static/8917d7d6092cda83b25603f97d78f84a/03b06/iPad_Wizard_Installation_8001_6001_10.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8917d7d6092cda83b25603f97d78f84a/81c8e/iPad_Wizard_Installation_8001_6001_10.png 230w", "/en/static/8917d7d6092cda83b25603f97d78f84a/08a84/iPad_Wizard_Installation_8001_6001_10.png 460w", "/en/static/8917d7d6092cda83b25603f97d78f84a/c0255/iPad_Wizard_Installation_8001_6001_10.png 920w", "/en/static/8917d7d6092cda83b25603f97d78f84a/b1001/iPad_Wizard_Installation_8001_6001_10.png 1380w", "/en/static/8917d7d6092cda83b25603f97d78f84a/161ec/iPad_Wizard_Installation_8001_6001_10.png 1840w", "/en/static/8917d7d6092cda83b25603f97d78f84a/49853/iPad_Wizard_Installation_8001_6001_10.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8917d7d6092cda83b25603f97d78f84a/c0255/iPad_Wizard_Installation_8001_6001_10.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[11]`}</strong>{` Sobald Ihre Kamera angeschlossen ist, klicken Sie auf `}<strong parentName="li">{`Suchen`}</strong>{` und die App wird alle INSTAR-Kameras in Ihrem lokalen Netzwerk erkennen. Wenn Ihre Kamera nicht angezeigt wird, wählen Sie die Option `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`manuell hinzufügen`}</a>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/49853/iPad_Wizard_Installation_8001_6001_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABX0lEQVQoz5WSSU5kMQyG3x1ZIiFxJSSO0AsEnANWoO4+AELMQypjxVMSo/dSVUxFAZ+ysCz/v53Ew8Oze3o219dXxhhEjHN48hgSIIzknInIWuucI6KcMwAgonP24uZxcCHmnBEx5yxSkDiBEIuIlFpbayISQvDel1Jaa7VWFqmlzKkOs5kxxuiS1toqzjmHidmE9z6EgIirgsF7n1Lqsm5sre0ZESEiWcLMRFRKeRVba2OMqlqnIUspzjlm1o304lFsjGlLaq3OuW73LUMphZnfij/Mtqnzh2xrDSdEZCxQ/XxUlVmYZWjvqbUCQEqJIGmVrzr3FVgj7p915fX8Vv/ejef/vf67W8Rnt3rplnf+Srx3olt/dOdQtw9091h3j8Zg53BM7p8uFmLNnfXHrOm8cBmNdO2DrfwX4tWG/a4zEQGAMWY+z8L06OnG0iwis9gJACAi732MsReHEHryBZ2saGd1Llc3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/e4706/iPad_Wizard_Installation_8001_6001_11.avif 230w", "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/d1af7/iPad_Wizard_Installation_8001_6001_11.avif 460w", "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/7f308/iPad_Wizard_Installation_8001_6001_11.avif 920w", "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/e1c99/iPad_Wizard_Installation_8001_6001_11.avif 1380w", "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/76ea5/iPad_Wizard_Installation_8001_6001_11.avif 1840w", "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/b105c/iPad_Wizard_Installation_8001_6001_11.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/a0b58/iPad_Wizard_Installation_8001_6001_11.webp 230w", "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/bc10c/iPad_Wizard_Installation_8001_6001_11.webp 460w", "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/966d8/iPad_Wizard_Installation_8001_6001_11.webp 920w", "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/445df/iPad_Wizard_Installation_8001_6001_11.webp 1380w", "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/78de1/iPad_Wizard_Installation_8001_6001_11.webp 1840w", "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/03b06/iPad_Wizard_Installation_8001_6001_11.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/81c8e/iPad_Wizard_Installation_8001_6001_11.png 230w", "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/08a84/iPad_Wizard_Installation_8001_6001_11.png 460w", "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/c0255/iPad_Wizard_Installation_8001_6001_11.png 920w", "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/b1001/iPad_Wizard_Installation_8001_6001_11.png 1380w", "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/161ec/iPad_Wizard_Installation_8001_6001_11.png 1840w", "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/49853/iPad_Wizard_Installation_8001_6001_11.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/faaa2aac3ca3f6ca959299b4b3fc5e03/c0255/iPad_Wizard_Installation_8001_6001_11.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[12]`}</strong>{` Sie werden nun aufgefordert, den Admin-Login Ihrer Kamera einzugeben. Wenn Sie es vorher nicht eingerichtet haben, wird dies der Standard-Userermane `}<strong parentName="li">{`admin`}</strong>{` und das Passwort `}<strong parentName="li">{`instar`}</strong>{` sein. Dies kann später innerhalb der App geändert werden.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/967afc5af883dfa33a9cfdd871ac9dae/49853/iPad_Wizard_Installation_8001_6001_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRElEQVQoz7WT3UrDMBSA+/J6I2PeTB9Eb8QLBTfY3mCg4IXbhV3T9DRtz0+THGm7DkTFDfEjHJKTfEkOJMl7Vuwys9m8GWOIyNW4A3Q1Eu5hZmttURQiMmSIqCjs6zZNitI1TUNETdO0rSeWCkVaH3u894joeogohBBj7GIINYckz40xRkdiCKrKzIMAAFmW5SNDBgBERFUTAKiqqtN6QggAkKapq2qktkFGbpFbki4iSY2MxN77TrbWOudU9XClPM+zbBdj0J+JMe5lY0wc6bfopm/Wevagk7lePOnlQqd9ZzLX80e9XQ++Jt57Efkq3z3rdKFXK50t9XrVtdmyG04Xev8yyt/e50iS+JkQ9qWGqP6HFuJv8p9OPkr+l5pPkA8v7LSTmRkRjTF13bTCKfDWcu5IpLU9w8cCAOfcsLgsyyH5AerAZ7B53ruSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/967afc5af883dfa33a9cfdd871ac9dae/e4706/iPad_Wizard_Installation_8001_6001_12.avif 230w", "/en/static/967afc5af883dfa33a9cfdd871ac9dae/d1af7/iPad_Wizard_Installation_8001_6001_12.avif 460w", "/en/static/967afc5af883dfa33a9cfdd871ac9dae/7f308/iPad_Wizard_Installation_8001_6001_12.avif 920w", "/en/static/967afc5af883dfa33a9cfdd871ac9dae/e1c99/iPad_Wizard_Installation_8001_6001_12.avif 1380w", "/en/static/967afc5af883dfa33a9cfdd871ac9dae/76ea5/iPad_Wizard_Installation_8001_6001_12.avif 1840w", "/en/static/967afc5af883dfa33a9cfdd871ac9dae/b105c/iPad_Wizard_Installation_8001_6001_12.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/967afc5af883dfa33a9cfdd871ac9dae/a0b58/iPad_Wizard_Installation_8001_6001_12.webp 230w", "/en/static/967afc5af883dfa33a9cfdd871ac9dae/bc10c/iPad_Wizard_Installation_8001_6001_12.webp 460w", "/en/static/967afc5af883dfa33a9cfdd871ac9dae/966d8/iPad_Wizard_Installation_8001_6001_12.webp 920w", "/en/static/967afc5af883dfa33a9cfdd871ac9dae/445df/iPad_Wizard_Installation_8001_6001_12.webp 1380w", "/en/static/967afc5af883dfa33a9cfdd871ac9dae/78de1/iPad_Wizard_Installation_8001_6001_12.webp 1840w", "/en/static/967afc5af883dfa33a9cfdd871ac9dae/03b06/iPad_Wizard_Installation_8001_6001_12.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/967afc5af883dfa33a9cfdd871ac9dae/81c8e/iPad_Wizard_Installation_8001_6001_12.png 230w", "/en/static/967afc5af883dfa33a9cfdd871ac9dae/08a84/iPad_Wizard_Installation_8001_6001_12.png 460w", "/en/static/967afc5af883dfa33a9cfdd871ac9dae/c0255/iPad_Wizard_Installation_8001_6001_12.png 920w", "/en/static/967afc5af883dfa33a9cfdd871ac9dae/b1001/iPad_Wizard_Installation_8001_6001_12.png 1380w", "/en/static/967afc5af883dfa33a9cfdd871ac9dae/161ec/iPad_Wizard_Installation_8001_6001_12.png 1840w", "/en/static/967afc5af883dfa33a9cfdd871ac9dae/49853/iPad_Wizard_Installation_8001_6001_12.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/967afc5af883dfa33a9cfdd871ac9dae/c0255/iPad_Wizard_Installation_8001_6001_12.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[13]`}</strong>{` Bitte wählen Sie einen Namen für Ihre Kamera.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7e79b78958765eca21d6cacecd3d781b/49853/iPad_Wizard_Installation_8001_6001_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABeUlEQVQoz6WS30pcMRCH87JeCYW+jfQVhKXYZ7A33or3tbvgOd3Jv5lkMsnkRI7rHipFKvjjI0yS+RICMdPR/znCfv8bAEoplIslZVFtXbWrLr0PxESUeh+qS2u9tY5Ij0/W+Ig551JKzllVuVSPlVJNqXiPAMG5OE0wz+AcWhtCoJyriHpSYy0AwDin9+7dKtSqKXGMRJSJmCgjJsTELDHSPAMimfUoojHGcg7AcZ7nZRkr45VTlmUtQ4iHw56IjHMOEf+WrQXEuPX/w+hdp2li5lUGgM1UVWt98P7uUXf3/cdDv3lYx63Y3fe7X80CEGWjqrXWTW5NQ+RMfHUrF9fty/d2uWtfb1Yud+v04rp9+1lLKj6wGW+j2mPkGFmZh5wpL5ynynzqMcvbtKanDR/YvYMP/5M/wufk99782ZtDyB+St0/SVRMyvuwhFsSytSJuxeuiERFmBoCUcqsyBznYClhrbdZ65wJzEakhIGISqcwlRmIWkfoMSztY0TUWdoIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7e79b78958765eca21d6cacecd3d781b/e4706/iPad_Wizard_Installation_8001_6001_13.avif 230w", "/en/static/7e79b78958765eca21d6cacecd3d781b/d1af7/iPad_Wizard_Installation_8001_6001_13.avif 460w", "/en/static/7e79b78958765eca21d6cacecd3d781b/7f308/iPad_Wizard_Installation_8001_6001_13.avif 920w", "/en/static/7e79b78958765eca21d6cacecd3d781b/e1c99/iPad_Wizard_Installation_8001_6001_13.avif 1380w", "/en/static/7e79b78958765eca21d6cacecd3d781b/76ea5/iPad_Wizard_Installation_8001_6001_13.avif 1840w", "/en/static/7e79b78958765eca21d6cacecd3d781b/b105c/iPad_Wizard_Installation_8001_6001_13.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7e79b78958765eca21d6cacecd3d781b/a0b58/iPad_Wizard_Installation_8001_6001_13.webp 230w", "/en/static/7e79b78958765eca21d6cacecd3d781b/bc10c/iPad_Wizard_Installation_8001_6001_13.webp 460w", "/en/static/7e79b78958765eca21d6cacecd3d781b/966d8/iPad_Wizard_Installation_8001_6001_13.webp 920w", "/en/static/7e79b78958765eca21d6cacecd3d781b/445df/iPad_Wizard_Installation_8001_6001_13.webp 1380w", "/en/static/7e79b78958765eca21d6cacecd3d781b/78de1/iPad_Wizard_Installation_8001_6001_13.webp 1840w", "/en/static/7e79b78958765eca21d6cacecd3d781b/03b06/iPad_Wizard_Installation_8001_6001_13.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7e79b78958765eca21d6cacecd3d781b/81c8e/iPad_Wizard_Installation_8001_6001_13.png 230w", "/en/static/7e79b78958765eca21d6cacecd3d781b/08a84/iPad_Wizard_Installation_8001_6001_13.png 460w", "/en/static/7e79b78958765eca21d6cacecd3d781b/c0255/iPad_Wizard_Installation_8001_6001_13.png 920w", "/en/static/7e79b78958765eca21d6cacecd3d781b/b1001/iPad_Wizard_Installation_8001_6001_13.png 1380w", "/en/static/7e79b78958765eca21d6cacecd3d781b/161ec/iPad_Wizard_Installation_8001_6001_13.png 1840w", "/en/static/7e79b78958765eca21d6cacecd3d781b/49853/iPad_Wizard_Installation_8001_6001_13.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7e79b78958765eca21d6cacecd3d781b/c0255/iPad_Wizard_Installation_8001_6001_13.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[14]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      